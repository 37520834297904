<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userReferralData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'my-referrals' }">
          referral List
        </b-link>
      </div>
    </b-alert>

    <template v-if="userReferralData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" md="12" xl="12" lg="12">
          <b-card>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-if="userReferralData.resume_file_path"
              variant="outline-info"
              class="ml-2"
              @click.stop.prevent="
                openWindow(userReferralData.resume_file_path)
              "
            >
              <feather-icon icon="EyeIcon" class="mr-25" />
              <span>View Resume</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="ml-2"
              @click="$router.go(-1)"
            >
              <feather-icon icon="ArrowLeftIcon" class="mr-25" />
              <span>Back To list</span>
            </b-button>
          </b-card>
        </b-col>
        <b-col cols="12" xl="12" lg="12" md="12">
          <referral-view-info :user-data="userReferralData" :custom-fields="customFields"/>
        </b-col>
      </b-row>
      <referral-view-timeline :history-data="userReferralData.histories" />
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink, BButton, VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import referralStoreModule from "../referralStoreModule";
import ReferralViewInfo from "./ReferralViewInfo.vue";
import ReferralViewTimeline from "./ReferralViewTimeline.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    ReferralViewInfo,
    ReferralViewTimeline,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const userReferralData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-referrals";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, referralStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-referrals/fetchReferral", router.currentRoute.params.id)
      .then((response) => {
        userReferralData.value = response.data.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userReferralData.value = undefined;
        }
      });

    return {
      userReferralData,
    };
  },
  data() {
    return {
      customFields: [],
      userData:[]
    };
  },
   created() {
    this.getCustomFields();
    this.userData.get_customfields_data = [];
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-referrals/getCustomFields")
        .then((res) => {
          if (res.data.success) {
            self.customFields = res.data.data;
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Custom Fields Getting Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Custom Fields Getting Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          // self.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! Custom Fields Getting Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
  },
};
</script>

<style>
</style>
